var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "d-flex align-center",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-card', [_c('v-row', {
    staticClass: "py-5"
  }, [_c('v-col', {
    staticClass: "text-center text-h6 pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("Du har nå oppdatert din profil")]), _c('v-col', {
    staticClass: "text-center text-caption pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Du kan trygt lukke dette vinduet.")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }